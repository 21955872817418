<template>
  <q-page padding @keyup.enter="getOrderAnalysisData">
    <div class="row filter-panel">
      <div class="column filter-column">
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('orderNumber') }}</div>
          <input type="text" v-model="orderNumber">
        </div>
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('partNumber') }}</div>
          <input type="text" v-model="partNumber">
        </div>
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('orderState') }}</div>
          <q-select v-model="orderState" :options="orderStateOptions" dense options-dense emit-value map-options
            option-value="value" option-label="name" />
        </div>
      </div>
      <div class="column filter-column">
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('machine') }}</div>
          <q-select v-model="stationNumber" :options="stationIdOptions()" dense options-dense emit-value map-options
            option-value="value" option-label="label" />
        </div>
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('dateRangeFilterType') }}</div>
          <q-select v-model="dateRangeFilterType" :options="dateRangeFilterTypeOptions" dense options-dense emit-value
            map-options option-value="value" option-label="name" />
        </div>
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('startFrom') }}</div>
          <span style="position: relative">
            <input type="text" :value="getFormattedDate(startDate)" @blur="setStartDate"
              :class="{ 'error': invalidDateRange }">
            <q-icon class="input-icon" name="event" @click="onShowStartDateDialog" />
          </span>
        </div>
        <div class="row filter-input">
          <div class="filter-input-label">{{ $t('endTo') }}</div>
          <span style="position: relative">
            <input type="text" :value="getFormattedDate(endDate)" @blur="setEndDate"
              :class="{ 'error': invalidDateRange }">
            <q-icon class="input-icon" name="event" @click="onShowEndDateDialog" />
          </span>
        </div>
      </div>
      <div class="column col-grow justify-end">
        <div class="row action-button">
          <q-btn icon="archive" color="primary" :title="$t('exportToCsv')" :label="$t('exportToCsv')" @click="exportTable"
            class="q-ma-xs" />
        </div>
        <div class="row action-button">
          <q-btn color="primary" :title="$t('action.search')" :label="$t('action.search')" :disable="isFilterInvalid()"
            @click="getOrderAnalysisData" class="q-ma-xs" />
        </div>
      </div>
    </div>
    <div v-if="!isLoadingData" class="q-pt-md">
      <q-table :rows="rows" :columns="columns" flat title="Orders" :visible-columns="visibleColumns"
        :pagination=pagination :pagination-label="paginationLabel" :rows-per-page-options="[5, 10, 20, 0]"
        :rows-per-page-label="$t('table.options.recordPerPage')" :no-data-label="$t('table.options.noMatchedRecords')"
        separator="cell" @update:pagination="updatePagination($event)">
        <template v-slot:header-cell="props">
          <q-th :props="props" class="th">
            <div class="table-header-label">{{ props.col.label }}</div>
            <q-icon class="column-order-button" name="arrow_left" size="1.5em"
              @click="moveColumnLeft($event, props.col.name)" />
            <q-icon class="column-order-button" name="arrow_right" size="1.5em"
              @click="moveColumnRight($event, props.col.name)" />
          </q-th>
        </template>
        <template v-slot:top>
          <q-select v-model="visibleColumns" multiple outlined dense options-dense :display-value="$t('visibleColumns')"
            emit-value map-options :options="columns" option-value="name" options-cover style="min-width: 150px"
            @update:model-value="updateVisibleColumns()" option-selected="isSelected">
            <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
              <q-item v-bind="itemProps" :class="{ hide: !canChangeColumVisibility(opt.field) }">
                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" :label="opt.label" />
              </q-item>
            </template>
          </q-select>
        </template>
      </q-table>
    </div>
    <div v-if="isLoadingData" class="q-pt-xl text-center">
      <q-spinner color="primary" size="3em" :thickness="10" />
    </div>
  </q-page>

  <!-- start date dialog -->
  <SettingsDialog :title="$t('startFrom')" v-model="showStartDateDialog" @ok="onConfirmStartDateDialog">
    <div class="q-pa-md">
      <div class="q-gutter-md row items-start">
        <q-date v-model="startDateTemp" mask="YYYY/MM/DD HH:mm" color="grey" />
        <q-time v-model="startDateTemp" mask="YYYY/MM/DD HH:mm" color="grey" format24h />
      </div>
    </div>
  </SettingsDialog>

  <!-- end date dialog -->
  <SettingsDialog :title="$t('endTo')" v-model="showEndDateDialog" @ok="onConfirmEndDateDialog">
    <div class="q-pa-md">
      <div class="q-gutter-md row items-start">
        <q-date v-model="endDateTemp" mask="YYYY/MM/DD HH:mm" color="grey" />
        <q-time v-model="endDateTemp" mask="YYYY/MM/DD HH:mm" color="grey" format24h />
      </div>
    </div>
  </SettingsDialog>

  <!-- error dialog -->
  <ErrorDialog :error="error" v-model="showErrorDialog" />
  <!-- info dialog -->
  <InfoDialog :content="info" v-model="showInfoDialog" />
</template>

<style>
.q-page {
  min-width: none;
}

.filter-panel {
  padding: 1em;
  background-color: #ededed;
}

.filter-column {
  width: 27%;
  margin-right: 5em;
}

.filter-input {
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.filter-input-label {
  min-width: 6.2rem;
}

.action-button {
  justify-content: end;
}

.filter-panel button,
.filter-panel .q-select,
.filter-panel input {
  background-color: white;
  min-width: 14rem;
  min-height: 2rem;
}

.filter-panel button,
.filter-panel .q-select,
.filter-panel input {
  height: 2.375rem;
}

.q-field--standard .q-field__control:before {
  border-bottom: none !important;
}

.q-field__marginal,
.q-field__native {
  height: auto !important;
  min-height: 20px !important;
}

input[type="search"],
input[type="text"],
.q-select,
.input-group-addon {
  color: #000000;
  border: 1px solid #C6CACD;
  box-shadow: none;
}

input[type="search"]:focus,
input[type="text"]:focus,
.q-select:focus,
.form-control:focus~.input-group-addon {
  color: #000000;
  border: 1px solid #000000;
  outline: 1px solid #000000;
  box-shadow: none;
}

.q-field--auto-height.q-field--dense .q-field__control,
.q-field--auto-height.q-field--dense .q-field__native {
  min-height: 38px !important;
}

.q-table .th {
  border-bottom: solid 2px black !important;
  border-left: none;
  font-weight: bold;
}

.q-table td {
  border-left: none !important;
}

.q-table thead tr,
.q-table tbody td {
  height: 40px !important;
}

.q-table tbody tr:last-child td {
  border-bottom: solid 2px black;
}

.q-table td {
  padding: 2px 16px !important;
}

.q-table th {
  padding: 6px 16px 2px 16px !important;
}

.th i.column-order-button {
  visibility: hidden;
}

.th:hover i.column-order-button {
  visibility: visible;
}

.q-icon.input-icon {
  position: absolute;
  right: 0.35rem;
  font-size: 20px;
  top: calc(50% - 0.58rem);
  color: gray;
  cursor: pointer;
}

input,
.q-field {
  border-radius: 4px !important;
}

input.error {
  border-color: red;
}

.hide {
  display: none !important;
}

.q-table__top {
  justify-content: flex-end;
}

.q-table__top .q-field {
  width: 224px;
  border: none;
}

.q-table__top .q-field .q-field__control {
  border-radius: 4px;
}

.table-header-label {
  white-space: pre;
}
</style>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { exportFile, useQuasar } from "quasar";
import { OrderStateEnum } from '../apis/OrderStateEnum';
import { DateRangeFilterTypeEnum } from '../apis/DateRangeFilterTypeEnum';
import FakeBackend from '../apis/fakeBackend'
// import ImsApiStandalone from "../services/ImsApiStandalone";
import MesApiCloud from "../apis/MesApiCloud";
import OrderAnalysisRow from "../models/orderAnalysisRow";
import SettingsDialog from "./global/SettingsDialog.vue"
import ErrorDialog from "./global/ErrorDialog.vue"
import InfoDialog from "./global/InfoDialog.vue"

import { useI18n } from 'vue-i18n';

const fakeBackend = new FakeBackend();

// const imsApiStandalone =
//   process.env.VUE_APP_DASHBOARD_ENVIRONMENT == "standalone"
//     ? new ImsApiStandalone()
//     : null;
// const imsApiCloud =
//   process.env.VUE_APP_DASHBOARD_ENVIRONMENT == "cloud"
//     ? new ImsApiCloud()
//     : null;
// const imsApiStandalone = null;
const { t, locale } = useI18n();
const $q = useQuasar();
const csvDelimiter = ';'
const mesApiCloud = new MesApiCloud();
let mesApi = null;
mesApi = mesApiCloud;
let orderStateOptions = [];
const orderState = ref(null);
let dateRangeFilterTypeOptions = [];
const dateRangeFilterType = ref(null);
const columns = ref();
const pagination = ref({
  page: 1,
  rowsPerPage: 10,
  sortBy: "",
  descending: false
});
const visibleColumns = ref([]);
const columnOrder = ref([]);
const startDate = ref("");
const endDate = ref("");
const rows = ref([]);
const stationIds = ref(null);
const stationNumber = ref(null);
const orderNumber = ref("");
const partNumber = ref("");
const startDateTemp = ref('');
const showStartDateDialog = ref(false);
const endDateTemp = ref('');
const showEndDateDialog = ref(false);
const error = ref({ title: '', message: '', details: '' });
const showErrorDialog = ref(false);
const info = ref({ title: '', message: '', details: '' });
const showInfoDialog = ref(false);
const sorting = {
  sortBy: '',
  descending: false
};
const invalidDateRange = ref(false);
const isLoadingData = ref(true);

const getColumns = () => {
  return [
    {
      name: "orderNumber",
      required: true,
      label: t("orderNumber"),
      align: "left",
      field: "orderNumber",
      sortable: true,
    },
    {
      name: "orderDescription",
      label: t("orderDescription"),
      align: "left",
      field: "orderDescription",
      sortable: true,
    },
    {
      name: "orderComment",
      label: t("orderComment"),
      align: "left",
      field: "orderComment",
      sortable: true,
    },
    {
      name: "partNumber",
      label: t("partNumber"),
      align: "left",
      field: "partNumber",
      sortable: true,
    },
    {
      name: "quantity",
      label: t("quantity"),
      align: "center",
      field: "quantity",
      sortable: true,
      sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
    },
    {
      name: "batchSize",
      label: t("batchSize"),
      align: "center",
      field: "batchSize",
      sortable: true,
    },
    {
      name: "bomVersion",
      label: t("bomVersion"),
      align: "center",
      field: "bomVersion",
      sortable: true,
    },
    {
      name: "workplanVersion",
      label: t("workplanVersion"),
      align: "center",
      field: "workplanVersion",
      sortable: true,
    },
    {
      name: "totalGoodQuantity",
      label: t("totalGoodQuantity"),
      align: "center",
      field: "totalGoodQuantity",
      sortable: true,
    },
    {
      name: "orderState",
      label: t("orderState"),
      align: "center",
      field: "orderState",
      sortable: true,
    },
    {
      name: "createdDate",
      label: t("createdDate"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDates(a, b),
      field: row => `${getFormattedDate(row.createdDate)}`,
      sortable: true,
    },
    {
      name: "scheduledStartTime",
      label: t("scheduledStartTime"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDates(a, b),
      field: row => `${getFormattedDate(row.scheduledStartTime)}`,
      sortable: true,
    },
    {
      name: "scheduledEndTime",
      label: t("scheduledEndTime"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDates(a, b),
      field: row => `${getFormattedDate(row.scheduledEndTime)}`,
      sortable: true,
    },
    {
      name: "overproduction",
      label: t("overproduction"),
      align: "center",
      sort: (a, b, rowA, rowB) => parseInt(a, 10) - parseInt(b, 10),
      field: (row) => {
        return `${(row.orderState === t("ORDER_STATE_OPTION." + OrderStateEnum.OPENED)) ? "" : (row.totalGoodQuantity - row.quantity)}`
      },
      sortable: true,
    },
    {
      name: "stationNumber",
      label: t("stationNumber"),
      align: "center",
      field: "stationNumber",
      sortable: true,
    },
    {
      name: "startDateOnStation",
      label: t("startDateOnStation"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDates(a, b),
      field: (row) => {
        return `${(!row.startDateOnStation || row.startDateOnStation.includes("1970")) ? "" : getFormattedDate(row.startDateOnStation)}`
      },
      sortable: true,
    },
    {
      name: "endDateOnStation",
      label: t("endDateOnStation"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDates(a, b),
      field: (row) => {
        return `${(!row.endDateOnStation || row.endDateOnStation.includes("1970")) ? "" : getFormattedDate(row.endDateOnStation)}`
      },
      sortable: true,
    },
    {
      name: "productionTime",
      label: t("productionTime"),
      align: "center",
      sort: (a, b, rowA, rowB) => sortDurations(a, b),
      field: (row) => {
        return `${(!row.startDateOnStation || row.startDateOnStation.includes("1970") || !row.endDateOnStation || row.endDateOnStation.includes("1970")) ? "" : formattedDuration(row.startDateOnStation, row.endDateOnStation)}`
      },
      sortable: true,
    },
    {
      name: "producedGoodQuantity",
      label: t("producedGoodQuantity"),
      align: "center",
      field: "producedGoodQuantity",
      sortable: true,
    },
    {
      name: "totalCuts",
      label: t("totalCuts"),
      align: "center",
      field: "totalCuts",
      sortable: true,
    }
  ]
};

const formattedDuration = (isoStartDateTime, isoEndDateTime) => {
  const start = new Date(isoStartDateTime);
  const end = new Date(isoEndDateTime);
  const diff = Math.abs(end - start);
  const hours = Math.floor(diff / 3600000);
  const minutes = Math.floor((diff % 3600000) / 60000);
  return `${hours}h ${minutes}`;
};

const reorderColumns = (columns, columnOrder) => {
  for (let i = columnOrder.length - 1; i >= 0; i--) {
    const columnIndex = columns.findIndex((c) => c.name == columnOrder[i])
    if (columnIndex >= 0) {
      const column = columns.splice(columnIndex, 1)[0];
      columns.unshift(column);
    }
  }
  return columns;
};

const getDateToday = () => {
  let today = new Date();
  today = getStandardDateString(today);
  return today;
};

const getStationIds = () => {
  console.log("getStationIds()");
  mesApi.getStationIds().then((result) => {
    console.log("successfully loaded StationIds:", result);
    stationIds.value = result;
    console.log("stationIds:", stationIds);
  })
    .catch((err) => {
      console.error("Error on getStationIds", err)
      error.value.title = err.message;
      error.value.message = err.response.data;
      error.value.details = JSON.stringify(err);
      showErrorDialog.value = true;
    });
};

const getOrderAnalysisData = () => {
  console.log("getOrderAnalysisData()");
  document.activeElement.blur()
  isLoadingData.value = true;
  const startD = new Date(startDate.value);
  let startDateString = null
  if (startD && !isNaN(startD)) {
    startDateString = new Date(startDate.value).toISOString();
  }

  const endD = new Date(endDate.value);
  let endDateString = null
  if (endD && !isNaN(endD)) {
    endDateString = new Date(endDate.value).toISOString();
  }

  console.log("orderNumber:", orderNumber.value);
  console.log("partNumber:", partNumber.value);
  console.log("orderState:", orderState.value);
  console.log("stationNumber:", stationNumber.value);
  console.log("startDate:", startDateString);
  console.log("endDate:", endDateString);
  console.log("dateRangeFilterType:", dateRangeFilterType.value);

  resetPagination();

  // if we want to have test data we can take it from the fakeBackend
  //rows.value = translateOrderAnalysisData(extractRows(fakeBackend.getOrderAnalysisData()))

  mesApi.getOrderAnalysisData(
    orderNumber.value,
    partNumber.value,
    orderState.value,
    stationNumber.value,
    startDateString,
    endDateString,
    dateRangeFilterType.value
  ).then(result => {
    console.log("successfully loaded OrderAnalysisData:", result);
    rows.value = translateOrderAnalysisData(extractRows(result.data));
    // Http 206 - Partial content.
    if (result.status === 206) {
      info.value.title = t("info.tooMuchDataFound.title");
      info.value.message = t("info.tooMuchDataFound.message");
      showInfoDialog.value = true;
    }
  }).catch((err) => {
    console.error("Error on getOrderAnalysisData", err)
    error.value.title = err;
    error.value.message = err.response.data;
    error.value.details = JSON.stringify(err);
    showErrorDialog.value = true;
  }).finally(() => {
    isLoadingData.value = false;
  });
};

const stationIdOptions = () => {
  const defaultOption = {
    label: t("AllMachines"),
    value: null
  };
  return stationIds.value
    ? [defaultOption, ...stationIds.value.map(({ stationName, stationId }) => ({ label: stationName + ' ' + stationId.substr(stationId.lastIndexOf('-') + 1), value: stationId }))]
    : [defaultOption];
}



const exportTable = () => {
  // naive encoding to csv format
  const header = columns.value.map(col => wrapCsvValue(col.label)).join(csvDelimiter);
  const content = [header].concat(
    rows.value.map(row => columns.value.map(col => wrapCsvValue(
      typeof col.field === 'function'
        ? col.field(row)
        : row[col.field === void 0 ? col.name : col.field],
      col.format,
      row)).join(csvDelimiter))
  ).join('\r\n')

  const bom = '\uFEFF'; // Add BOM character at the beginning

  const status = exportFile(
    'table-export.csv',
    bom + content, // Prepend the BOM character to the content
    'text/csv'
  )
  if (status !== true) {
    $q.notify({
      message: 'Browser denied file download...',
      color: 'negative',
      icon: 'warning'
    })
  }
}

const wrapCsvValue = (val, formatFn, row) => {
  let formatted = formatFn !== void 0
    ? formatFn(val, row)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
    .split('\n').join(' ');

  return formatted
}

const updateVisibleColumns = () => {
  console.log("update visible columns in local storage")
  reorderColumns(columns.value, columnOrder.value);
  localStorage.visibleColumns = visibleColumns.value;
};

const updateColumnOrder = () => {
  console.log("update column order in local storage")
  reorderColumns(columns.value, columnOrder.value);
  localStorage.columnOrder = columnOrder.value;
};

const updatePagination = (event) => {
  console.log("sort table and update pagination in local storage", event)

  pagination.value.sortBy = event.sortBy;
  pagination.value.descending = event.descending;

  if (sorting.sortBy) {
    const sortField = columns.value.find(column => column.field === sorting.sortBy);
    rows.value = rows.value.sort((a, b) => {
      const aValue = a[sortField.field];
      const bValue = b[sortField.field];
      if (aValue < bValue) {
        return sorting.descending ? 1 : -1;
      }
      if (aValue > bValue) {
        return sorting.descending ? -1 : 1;
      }
      return 0;
    });
  }

  pagination.value.page = event.page;
  pagination.value.rowsPerPage = event.rowsPerPage;
  localStorage.pagination = JSON.stringify(pagination.value);
};

const resetPagination = () => {
  pagination.value.page = 1;
  localStorage.pagination = JSON.stringify(pagination.value);
}

const translateOrderAnalysisData = (orderAnalysisRows) => {
  for (let i = 0; i < orderAnalysisRows.length; i++) {
    const element = orderAnalysisRows[i];
    element.orderState = t('ORDER_STATE_OPTION.' + element.orderState);
  }
  return orderAnalysisRows;
};

const extractRows = (orderAnalysisData) => {
  console.log("orderAnalysisData", orderAnalysisData)
  let orderAnalysisRows = [];
  for (let i = 0; i < orderAnalysisData.length; i++) {
    for (let j = 0; j < orderAnalysisData[i].productionInformation.length; j++) {
      orderAnalysisRows.push(new OrderAnalysisRow(orderAnalysisData[i], j))
    }
  }
  console.log("orderAnalysisRows", orderAnalysisRows)
  return orderAnalysisRows;
};

const paginationLabel = (firstRowIndex, endRowIndex, totalRowsNumber) => {
  return t("table.options.showing", { firstRowIndex, endRowIndex, totalRowsNumber })
};

const moveColumnLeft = (event, columnName) => {
  event.stopPropagation();
  const index = columnOrder.value.findIndex(column => column === columnName);
  if (index > 0) {
    const column = columnOrder.value.splice(index, 1)[0];
    let j = 0;
    while (index - (j + 1) > 0 && !visibleColumns.value.includes(columnOrder.value[index - (j + 1)])) {
      j++;
    }
    columnOrder.value.splice(index - (j + 1), 0, column);
  }
  updateColumnOrder();
};

const moveColumnRight = (event, columnName) => {
  event.stopPropagation();
  const index = columnOrder.value.findIndex(column => column === columnName);
  if (index >= 0 && index < columnOrder.value.length - 1) {
    const column = columnOrder.value.splice(index, 1)[0];
    let j = 0;
    while (index + (j + 1) < columnOrder.value.length && !visibleColumns.value.includes(columnOrder.value[index + j])) {
      j++;
    }
    columnOrder.value.splice(index + (j + 1), 0, column);
  }
  updateColumnOrder();
};

const onShowStartDateDialog = () => {
  if (startDate.value !== "") {
    startDateTemp.value = startDate.value;
  }
  else {
    startDateTemp.value = getDateToday() + " 00:00";
  }
  showStartDateDialog.value = true;
};

const onConfirmStartDateDialog = () => {
  startDate.value = startDateTemp.value;
  showStartDateDialog.value = false;
  validateDateRange();
};

const onShowEndDateDialog = () => {
  if (endDate.value !== "") {
    endDateTemp.value = endDate.value;
  }
  else {
    endDateTemp.value = getDateToday() + " 23:59";
  }
  showEndDateDialog.value = true;
};

const onConfirmEndDateDialog = () => {
  endDate.value = endDateTemp.value;
  showEndDateDialog.value = false;
  validateDateRange();
};

const validateDateRange = () => {
  const endD = new Date(endDate.value);
  const startD = new Date(startDate.value);
  invalidDateRange.value = startD >= endD;
};

const isFilterInvalid = () => {
  return invalidDateRange.value;
};

const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  if (date && !isNaN(date)) {
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    const DD = String(date.getDate()).padStart(2, "0");
    const MM = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const YYYY = date.getFullYear();
    const formattedDateString = DD + "." + MM + "." + YYYY + " " + hh + ":" + mm;
    return formattedDateString;
  }
  return "";
}

const setStartDate = (inputEvent) => {
  const [day, month, year, hours, minutes] = inputEvent.currentTarget.value.split(/[.: ]/);
  const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
  const date = new Date(isoDateString)
  if (date && !isNaN(date)) {
    startDate.value = getStandardDateString(date) + ` ${hours}:${minutes}`
  } else {
    startDate.value = "";
  }
}

const setEndDate = (inputEvent) => {
  const [day, month, year, hours, minutes] = inputEvent.currentTarget.value.split(/[.: ]/);
  const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
  const date = new Date(isoDateString)
  if (date && !isNaN(date)) {
    endDate.value = getStandardDateString(date) + ` ${hours}:${minutes}`;
  } else {
    endDate.value = "";
  }
}

const getStandardDateString = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  let dateString = yyyy + "/" + mm + "/" + dd;
  return dateString;
}

const canChangeColumVisibility = (columnName) => {
  // the visibility of the column "orderNumber" shall not be changeable
  if (columnName === columns.value[0].name) {
    return false;
  }
  return true;
}

const getOrderStateOptions = () => {
  const orderStateEnumOptions = Object.values(OrderStateEnum).map((option) => {
    return {
      value: option,
      name: t('ORDER_STATE_OPTION.' + option)
    };
  });

  return [
    { name: t("SelectAll"), value: null },
    ...orderStateEnumOptions
  ]
}

const getDateRangeFilterTypeOptions = () => {
  const dateRangeFilterTypeEnumOptions = Object.values(DateRangeFilterTypeEnum).map((option) => {
    return {
      value: option,
      name: t("DATE_RANGE_FILTER_TYPE_OPTION." + option)
    };
  });

  return [
    ...dateRangeFilterTypeEnumOptions
  ]

}

const sortDates = (a, b) => {
    const dateA = convertToDate(a);
    const dateB = convertToDate(b);
    return dateA - dateB;
}

const convertToDate = (dateString) => {
  if (dateString === '') return new Date(0); // Return the epoch date for empty strings
  const [day, month, year, time] = dateString.split(/[ .:]+/);
  const hours = parseInt(time, 10);
  const minutes = parseInt(time.slice(-2), 10);
  const date = new Date(year, month - 1, parseInt(day, 10), hours, minutes);
  return date;  
}

const sortDurations = (a, b) => {
    const durationA = convertToMinutes(a);
    const durationB = convertToMinutes(b);
    return durationA - durationB;
}

const convertToMinutes = (duration) => {
  const parts = duration.split(' ');
  const hours = parseInt(parts[0].replace('h', ''), 10);
  const minutes = parseInt(parts[1], 10);
  return hours * 60 + minutes;
}

columns.value = getColumns();
orderStateOptions = getOrderStateOptions();
orderState.value = orderStateOptions[0].value;

dateRangeFilterTypeOptions = getDateRangeFilterTypeOptions();
dateRangeFilterType.value = dateRangeFilterTypeOptions[0].value;

for (let i = 0; i < columns.value.length; i++) {
  visibleColumns.value.push(columns.value[i].name);
  columnOrder.value.push(columns.value[i].name);
}

if (localStorage.visibleColumns) {
  visibleColumns.value = localStorage.visibleColumns.split(",");
} else {
  localStorage.visibleColumns = visibleColumns.value;
}

if (localStorage.columnOrder) {
  columnOrder.value = localStorage.columnOrder.split(",");
  reorderColumns(columns.value, columnOrder.value);
} else {
  localStorage.columnOrder = columnOrder.value;
}

if (localStorage.pagination) {
  pagination.value = JSON.parse(localStorage.pagination);
} else {
  localStorage.pagination = JSON.stringify(pagination.value);
}

startDate.value = getDateToday() + " 00:00";
endDate.value = getDateToday() + " 23:59";

onMounted(() => {
  getStationIds();
  getOrderAnalysisData();
});

watch(locale, () => {
  columns.value = getColumns();
  orderStateOptions = getOrderStateOptions();
  dateRangeFilterTypeOptions = getDateRangeFilterTypeOptions();
});

</script>