import { createI18n } from "vue-i18n";

// Define a function to load translations from JSON files
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// Create a new instance of i18n
const i18n = createI18n({
  legacy: false, // Set to false to use Composition API
  locale: 'en', // Set the default locale to English
  fallbackWarn: false,
  fallbackLocale: 'en', // Set the fallback locale to English
  messages: loadLocaleMessages() // Load translations dynamically from JSON files
});

// Export the i18n instance for use in the app
export default i18n;
