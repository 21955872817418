import Oidc, { UserManager, WebStorageStateStore, User } from "oidc-client";
import { ref } from "vue";

export default class AuthService {
  private userManager: UserManager;
  private STS_DOMAIN = "";
  private PORTAL_URL = "";
  private currentUser = ref({
    name: "test-user",
    organisation: "test-organisation",
  });
  private isUserLoggedIn = ref(false);
  public FDV_URL = "";

  constructor() {
    if (process.env.VUE_APP_CLOUD_STAGE === "dev") {
      this.STS_DOMAIN = "https://komax-dmi-sts-we-dev-web.azurewebsites.net";
      this.FDV_URL =
        "https://komax-dmi-feedbackdataviewer-dev.azurewebsites.net/";
      this.PORTAL_URL = "https://komax-dmi-portal-we-dev-web.azurewebsites.net";
    } else if (process.env.VUE_APP_CLOUD_STAGE === "test") {
      this.STS_DOMAIN = "https://komax-dmi-sts-we-test-web.azurewebsites.net";
      this.FDV_URL =
        "https://komax-dmi-feedbackdataviewer-test-web.azurewebsites.net/";
      this.PORTAL_URL =
        "https://komax-dmi-portal-we-test-web.azurewebsites.net";
    } else if (process.env.VUE_APP_CLOUD_STAGE === "prod") {
      this.STS_DOMAIN = "https://sts.komaxgroup.com/";
      this.FDV_URL =
        "https://cloudmes-feedbackdataviewer.komaxgroup.com/";
      this.PORTAL_URL = "https://iot.komaxgroup.com";
    } else {
      this.STS_DOMAIN = "https://komax-dmi-sts-we-dev-web.azurewebsites.net";
      this.FDV_URL = "https://localhost:44390/";
      this.PORTAL_URL = "https://komax-dmi-portal-we-dev-web.azurewebsites.net";
    }

    const settings: any = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: this.STS_DOMAIN,
      client_id: "feedbackdataviewer_client",
      redirect_uri: this.FDV_URL + "oidc-callback.html",
      automaticSilentRenew: true,
      silent_redirect_uri: this.FDV_URL + "silent-renew-oidc.html",
      response_type: "code",
      scope: "openid profile email feedbackdataviewer",
      post_logout_redirect_uri: this.PORTAL_URL,
      filterProtocolClaims: true,
    };

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(() => {
      console.log("user signed out");
      this.clearUserInLocalStorage();
      location.reload();
    });
    
    this.userManager.events.addSilentRenewError((error) => {
      console.error('Silent renew error:', error);
      window.location.href = this.PORTAL_URL;      
    });

    // Enable logging
    // Oidc.Log.logger = console;
    // Oidc.Log.level = Oidc.Log.INFO; // Set the desired logging level (INFO, DEBUG, WARN, ERROR)
  }

  public initialize() {
    if (process.env.VUE_APP_AUTH_ENABLE === "true") {
      this.getUser().then((user) => {
        console.log("AuthService user received:", user);
        if (user !== null && user.profile !== null && !user.expired) {
          this.signinSilent().then((user) => {
            console.log("AuthService signinSilent done");
            this.currentUser.value.name = user.profile.name ?? "";
            this.currentUser.value.organisation = user.profile.organization_name;
            this.isUserLoggedIn.value = true;
          });
        } else {
          this.signin().then(() => {
            console.log("AuthService signin done");
          });
        }
      });
    }
    else {
      this.isUserLoggedIn.value = true;
    }
  }

  public getIsUserLoggedIn() {
    return this.isUserLoggedIn;
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  private signin(): Promise<void> {
    console.log("AuthService signin()")
    return this.userManager.signinRedirect();
  }

  private signinSilent(): Promise<User> {
    console.log("AuthService signinSilent()")
    return this.userManager.signinSilent();
  }

  private getUser(): Promise<User | null> {
    console.log("AuthService getUser() with oidcClient:", this.userManager);
    return this.userManager.getUser();
  }

  public getAccessToken(): Promise<string | undefined> {
    console.log("AuthService getAccessToken()");
    return this.getUser().then((user) => {
      return user?.access_token;
    })
  }

  private clearUserInLocalStorage() {
    console.log("AuthService clearUserInLocalStorage()")
    for (const key in localStorage) {
      if (key.includes("oidc")) {
        localStorage.removeItem(key);
      }
    }
  }
}
