<template>
  <q-dialog v-model="show">
    <q-card>
      <q-card-section class="row items-center">
        <div class="text-h6">Error</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="q-pt-none" style="white-space: pre-line">
        <strong>{{ error.title }}</strong>
      </q-card-section>

      <q-card-section class="q-pt-none" style="white-space: pre-line">
        {{ error.message }}
      </q-card-section>

      <q-card-section>
        <q-expansion-item expand-separator label="Details">
          <q-card>
            <q-card-section style="word-break: break-all;">
              {{ error.details }}
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style>
.q-expansion-item .q-item {
  padding: 0;
}

.q-expansion-item .q-item__section--main {
  flex: none;
}
</style>

<script>
export default {
  props: {
    error: { title: String, message: String, details: String }
  },
}
</script>
