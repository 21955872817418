import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "./plugins/i18n/i18n";

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(i18n);
app.mount("#app");
