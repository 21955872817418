<template>
  <q-layout view="hHh lpr fFf">
    <q-header>
      <div class="kx-app-header">
        <div class="kx-app-suit">
          <div class="kx-title-wrapper">
            <div class="kx-title-company">KOMAX</div>
            <div class="kx-title-app">Cloud MES Feedback Data Viewer</div>
          </div>
        </div>

        <div v-if="isUserLoggedIn" class="app-info">
          <div>
            FDV Version: 1.0.0-{{ commitHash + environment}}
          </div>
          <div>
            Organisation: {{ authService.getCurrentUser().value.organisation }}
          </div>
          <div>
            <q-icon name="person" />
            {{ authService.getCurrentUser().value.name }}
          </div>
        </div>
      </div>
    </q-header>
    <q-page-container v-if="isUserLoggedIn">
      <OrdersViewer />
    </q-page-container>
  </q-layout>
</template>

<style>
@import "@/styles/styles.scss";

header {
  min-height: 50px;
  padding: 15px;
  height: 112px;
}

.kx-app-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.kx-app-suit {
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 5px;
  overflow: hidden;
  color: #fff;
  height: 80px;
  z-index: 10;
}

.kx-app-suit:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 80px;
  background: #4d4e4d;
  -webkit-transform-origin: left -0.5cm;
  -ms-transform-origin: left -0.5cm;
  transform-origin: left -0.5cm;
  -webkit-transform: skew(-35deg);
  -ms-transform: skew(-35deg);
  transform: skew(-35deg);
  z-index: -1;
}

.kx-title-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 24px;
  padding-left: 24px;
  padding-top: 8px;
}

.kx-title-company {
  font-weight: 600;
  font-size: 10pt;
}

.kx-title-app {
  font-weight: 800;
  font-size: 14pt;
}

.q-layout__section--marginal {
  background-color: white !important;
}

.app-info {
  position: absolute;
  right: 28px;
  top: 10px;
  margin-right: 24px;
  color: #333;
  font-weight: 700;
}
</style>

<script setup>
import { onMounted, provide, ref } from "vue";
import OrdersViewer from "./components/OrdersViewer.vue";
import AuthService from "./services/AuthService";
import { useI18n } from 'vue-i18n';

const authService = new AuthService();
const { locale } = useI18n();
const isUserLoggedIn = authService.getIsUserLoggedIn();
const commitHash = ref(null);
const environment = ref(null);

provide('authService', authService);

onMounted(() => {
  fetchCommitHash();
  getEnvironment();
  authService.initialize();
  var url = new URL(window.location.href);
  var culture = url.searchParams.get("culture");
  console.log("culture", culture);
  if (culture == "de-CH") {
    const lang = "de";
    console.log("lang: " + lang);
    locale.value = lang;
  }
});

const fetchCommitHash = async () => {
      try {
        const response = await fetch('/commitHash.txt');
        commitHash.value = await response.text();
        console.log('fetched commit hash:', commitHash.value);
      } catch (error) {
        console.error('Failed to fetch commit hash:', error);
      }
    };

    const getEnvironment = () => {
      environment.value = (process.env.VUE_APP_CLOUD_STAGE === "prod" ? "" : "." + process.env.VUE_APP_CLOUD_STAGE.toUpperCase())
    }
</script>
