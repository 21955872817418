<template>
  <q-dialog v-model="show">
    <q-card>
      <q-card-section class="row items-center">
        <div class="text-h6">Info</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="q-pt-none" style="white-space: pre-line">
        <strong>{{ content.title }}</strong>
      </q-card-section>

      <q-card-section class="q-pt-none" style="white-space: pre-line">
        {{ content.message }}
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style>
.q-expansion-item .q-item {
  padding: 0;
}

.q-expansion-item .q-item__section--main {
  flex: none;
}
</style>

<script>
export default {
  props: {
    content: { title: String, message: String }
  },
}
</script>
