import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./App.vue?vue&type=style&index=0&id=08e966fe&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QIcon,QPageContainer});
