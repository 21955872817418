import { GetOrderAnalysisDataDto } from "./GetOrderAnalysisDataDto";

export default class FakeBackend {
  getOrderAnalysisData(): GetOrderAnalysisDataDto[] {
    return [
      {
        orderNumber: "string",
        orderDescription: "string",
        orderComment: "string",
        partNumber: "string",
        quantity: 0,
        batchSize: 0,
        bomVersion: 0,
        workplanVersion: 0,
        totalGoodQuantity: 0,
        orderState: "OPENED",
        createdDate: "2023-04-04T12:38:09.320Z",
        scheduledStartTime: "2023-04-04T12:38:09.320Z",
        scheduledEndTime: "2023-04-04T12:38:09.320Z",
        productionInformation: [
          {
            stationNumber: "string",
            startDateOnStation: "2023-04-04T12:38:09.320Z",
            endDateOnStation: "2023-04-04T12:38:09.320Z",
            producedGoodQuantity: 0,
            totalCuts: 0,
          },
        ],
      },
      {
        orderNumber: "12345",
        orderDescription: "Widget production",
        orderComment: "Rush order",
        partNumber: "WID-123",
        quantity: 100,
        batchSize: 20,
        bomVersion: 2,
        workplanVersion: 3,
        totalGoodQuantity: 75,
        orderState: "OPENED",
        createdDate: "2023-04-03T08:30:00.000Z",
        scheduledStartTime: "2023-04-04T08:00:00.000Z",
        scheduledEndTime: "2023-04-05T16:00:00.000Z",
        productionInformation: [
          {
            stationNumber: "Station 1",
            startDateOnStation: "2023-04-04T08:15:00.000Z",
            endDateOnStation: "2023-04-04T10:30:00.000Z",
            producedGoodQuantity: 20,
            totalCuts: 50,
          },
          {
            stationNumber: "Station 2",
            startDateOnStation: "2023-04-04T10:45:00.000Z",
            endDateOnStation: "2023-04-04T12:30:00.000Z",
            producedGoodQuantity: 30,
            totalCuts: 60,
          },
          {
            stationNumber: "Station 3",
            startDateOnStation: "2023-04-04T13:00:00.000Z",
            endDateOnStation: "2023-04-04T15:30:00.000Z",
            producedGoodQuantity: 25,
            totalCuts: 55,
          },
        ],
      },
      {
        orderNumber: "ORD001",
        orderDescription: "Widget production",
        orderComment: "Urgent order",
        partNumber: "PRT001",
        quantity: 100,
        batchSize: 20,
        bomVersion: 2,
        workplanVersion: 1,
        totalGoodQuantity: 80,
        orderState: "OPENED",
        createdDate: "2023-04-04T12:38:09.320Z",
        scheduledStartTime: "2023-04-05T09:00:00.000Z",
        scheduledEndTime: "2023-04-06T17:00:00.000Z",
        productionInformation: [
          {
            stationNumber: "STN001",
            startDateOnStation: "2023-04-05T09:00:00.000Z",
            endDateOnStation: "2023-04-05T11:00:00.000Z",
            producedGoodQuantity: 20,
            totalCuts: 100,
          },
          {
            stationNumber: "STN002",
            startDateOnStation: "2023-04-05T11:00:00.000Z",
            endDateOnStation: "2023-04-06T17:00:00.000Z",
            producedGoodQuantity: 60,
            totalCuts: 300,
          },
        ],
      },
      {
        orderNumber: "ORD002",
        orderDescription: "Gadget assembly",
        orderComment: "Rush job",
        partNumber: "PRT002",
        quantity: 50,
        batchSize: 10,
        bomVersion: 1,
        workplanVersion: 1,
        totalGoodQuantity: 40,
        orderState: "STARTED",
        createdDate: "2023-04-04T12:38:09.320Z",
        scheduledStartTime: "2023-04-05T13:00:00.000Z",
        scheduledEndTime: "2023-04-06T15:00:00.000Z",
        productionInformation: [
          {
            stationNumber: "STN001",
            startDateOnStation: "2023-04-05T13:00:00.000Z",
            endDateOnStation: "2023-04-05T15:00:00.000Z",
            producedGoodQuantity: 10,
            totalCuts: 50,
          },
          {
            stationNumber: "STN002",
            startDateOnStation: "2023-04-05T15:00:00.000Z",
            endDateOnStation: "2023-04-06T15:00:00.000Z",
            producedGoodQuantity: 30,
            totalCuts: 150,
          },
        ],
      },
    ];
  }
}
