<template>
  <q-dialog v-model="show">
    <q-card style="max-width: none">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ title }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
      <slot></slot>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="onOk" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>

export default {
  props: {
    title: String,
  },
  methods: {
    onOk() {
      this.$emit('ok')
    }
  }
}
</script>
