import axios from "axios";
import { inject } from 'vue';
import { DataAccessInterface } from "./DataAccessInterface";
import { GetOrderAnalysisDataDto } from "./GetOrderAnalysisDataDto";
import { StationsGetDto } from "./StationsGetDto";
import AuthService from "../services/AuthService";
import { GetOrderAnalysisDataResponse } from "./GetOrderAnalysisDataResponse";

export default class MesApiCloud implements DataAccessInterface {
  authService: undefined | AuthService;
  private apiUrl = "";

  constructor() {
    this.authService = inject("authService");    
    this.apiUrl = this.authService?.FDV_URL + "api/mes";
  }

  public async getStationIds(): Promise<StationsGetDto[]> {
    if (this.authService && process.env.VUE_APP_AUTH_ENABLE === 'true') {
        console.log("accessToken:", this.authService.getAccessToken());

        if (this.authService.getAccessToken() === null || " " === null) {
          return new Promise<StationsGetDto[]>(() => []);
        }

        return this.getStationIdsRequest(await this.authService.getAccessToken());
    }

    return this.getStationIdsRequest();
  }

  private getStationIdsRequest(accessToken = ' '): Promise<StationsGetDto[]> {
    return axios
      .get(this.apiUrl + "/GetStationIds", {
        headers: { Authorization: "Bearer " +  accessToken},
      })
      .then((response) => {
        console.log(response);
        return response.data as StationsGetDto[];
      });
  }

  public async getOrderAnalysisData(
    orderNumber?: string,
    partNumber?: string,
    orderState?: string,
    stationNumber?: string,
    dateRangeFilterType?: string,
    startDate?: string,
    endDate?: string
  ): Promise<GetOrderAnalysisDataResponse> {
    if (this.authService && process.env.VUE_APP_AUTH_ENABLE === 'true') {
        console.log("accessToken:", this.authService.getAccessToken());

        if (this.authService.getAccessToken() === null || " " === null) {
          return new Promise<GetOrderAnalysisDataResponse>((resolve) => {
            resolve({ status: 0, data: [] });
          });
        }

        return this.getOrderAnalysisDataRequest(
          orderNumber,
          partNumber,
          orderState,
          stationNumber,
          dateRangeFilterType,
          startDate,
          endDate,
          await this.authService.getAccessToken()
        );
    }
    return this.getOrderAnalysisDataRequest(
      orderNumber,
      partNumber,
      orderState,
      stationNumber,
      dateRangeFilterType,
      startDate,
      endDate
    );
  }

  private getOrderAnalysisDataRequest(
    orderNumber: string | undefined,
    partNumber: string | undefined,
    orderState: string | undefined,
    stationNumber: string | undefined,
    startDate: string | undefined,
    endDate: string | undefined,
    dateRangeFilterType: string | undefined,
    accessToken = ' '
  ) {
    const PARAMS = {
      orderNumber: orderNumber,
      partNumber: partNumber,
      orderState: orderState,
      stationNumber: stationNumber,
      dateRangeFilterType: dateRangeFilterType,
      startDate: startDate,
      endDate: endDate
    };

    return axios
      .get(this.apiUrl + "/GetOrderAnalysisData", {
        headers: { Authorization: "Bearer " + accessToken },
        params: PARAMS,
      })
      .then((response) => {
        console.log("getOrderAnalysisDataRequest response", response);
        return {status: response.status, data: response.data as GetOrderAnalysisDataDto[]};
      });
  }
}
