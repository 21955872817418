import script from "./OrdersViewer.vue?vue&type=script&setup=true&lang=js"
export * from "./OrdersViewer.vue?vue&type=script&setup=true&lang=js"

import "./OrdersViewer.vue?vue&type=style&index=0&id=60373d6e&lang=css"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QSelect,QIcon,QBtn,QTable,QTh,QItem,QCheckbox,QSpinner,QDate,QTime,QField});
