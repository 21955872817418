import { OrderStateEnum } from "../apis/OrderStateEnum";
import { GetOrderAnalysisDataDto } from "../apis/GetOrderAnalysisDataDto";

export default class OrderAnalysisRow {
  orderNumber?: string;
  orderDescription?: string;
  orderComment?: string;
  partNumber?: string;
  quantity?: number;
  batchSize?: number;
  bomVersion?: number;
  workplanVersion?: number;
  totalGoodQuantity?: number;
  orderState?: OrderStateEnum;
  createdDate?: string;
  scheduledStartTime?: string;
  scheduledEndTime?: string;
  stationNumber?: string;
  startDateOnStation?: string;
  endDateOnStation?: string;
  producedGoodQuantity?: number;
  totalCuts?: number;

  constructor(
    orderAnalysisData: GetOrderAnalysisDataDto,
    productInformationIndex: number
  ) {
    this.orderNumber = orderAnalysisData.orderNumber;
    this.orderDescription = orderAnalysisData.orderDescription;
    this.orderComment = orderAnalysisData.orderComment;
    this.partNumber = orderAnalysisData.partNumber;
    this.quantity = orderAnalysisData.quantity;
    this.batchSize = orderAnalysisData.batchSize;
    this.bomVersion = orderAnalysisData.bomVersion;
    this.workplanVersion = orderAnalysisData.workplanVersion;
    this.totalGoodQuantity = orderAnalysisData.totalGoodQuantity;
    this.orderState = orderAnalysisData.orderState;
    this.createdDate = orderAnalysisData.createdDate;
    this.scheduledStartTime = orderAnalysisData.scheduledStartTime;
    this.scheduledEndTime = orderAnalysisData.scheduledEndTime;
    if (
      orderAnalysisData.productionInformation.length > productInformationIndex
    ) {
      this.stationNumber =
        orderAnalysisData.productionInformation[
          productInformationIndex
        ].stationNumber;
      this.startDateOnStation =
        orderAnalysisData.productionInformation[
          productInformationIndex
        ].startDateOnStation;
      this.endDateOnStation =
        orderAnalysisData.productionInformation[
          productInformationIndex
        ].endDateOnStation;
      this.producedGoodQuantity =
        orderAnalysisData.productionInformation[
          productInformationIndex
        ].producedGoodQuantity;
      this.totalCuts =
        orderAnalysisData.productionInformation[
          productInformationIndex
        ].totalCuts;
    }
  }
}
